import Vue from "vue";
import Router from "vue-router";
import Schedule from "./views/Schedule.vue";
import Hardware from "./views/Hardware.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "hardware",
      component: Hardware
    },
    {
      path: "/map",
      name: "map",
      component: () => import("./views/Map.vue")
    },
    {
      path: "/hardware",
      name: "hardware",
      component: () => import("./views/Hardware.vue")
    },
    {
      path: "/hardwareICRS",
      name: "hardwareICRS",
      component: () => import("./views/HardwareICRS.vue")
    },
    {
      path: "/github",
      name: "github",
      component: () => import("./views/GitHub.vue")
    },
    {
      path: "/auth",
      name: "auth",
      component: () => import("./views/auth.vue")
    },
    {
      path: "/admin",
      name: "admin",
      component: () => import("./views/admin.vue")
    },
    {
      path: "/adminICRS",
      name: "adminICRS",
      component: () => import("./views/adminICRS.vue")
    }
  ]
});
