<template>
  <v-container>
    <v-layout :justify-center="!$vuetify.breakpoint.smAndDown">
      <v-card color="#C5B4E3" dark>
        <v-card-title class="display-1">Schedule</v-card-title>
      </v-card>
    </v-layout>
    <v-layout justify-center>
      <v-flex xs12 lg10 text-xs-center>
        <v-timeline :dense="$vuetify.breakpoint.smAndDown">
          <v-timeline-item
            v-for="(event, i) in timeline"
            :key="i"
            :color="event.color"
            :icon="event.icon"
            fill-dot
          >
            <span slot="opposite">
              <h2>{{ event.day }} at {{ event.time }}</h2>
            </span>

            <v-card :color="event.color" dark>
              <div
                class="overline pl-1 pt-1"
                v-if="$vuetify.breakpoint.smAndDown"
              >
                <h2>{{ event.day }} at {{ event.time }}</h2>
              </div>
              <v-card-title class="title">{{ event.name }}</v-card-title>
              <v-card-text class="white text--primary" v-if="event.description">
                <p>
                  {{ event.description }}
                </p>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import timeline from "../assets/timeline.json";

export default {
  data: () => ({
    timeline: timeline
  })
};
</script>
