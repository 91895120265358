<template>
  <v-app id="inspire">
    <v-navigation-drawer
      style="backgroundColor: #C5B4E3"
      v-model="drawer"
      fixed
      app
      width="250"
    >
      <a href="/">
        <img
          style="width: 220px; margin: 10px"
          alt="Vue logo"
          src="./assets/logo.png"
        />
      </a>
      <v-list>
        <!-- <v-list-item to="/">
          <v-list-item-content>
            <v-list-item-title
              style="font-family: 'Source Sans Pro', sans-serif !important; font-weight: bold;"
              class="white--text title ml-2"
            >
              r</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item to="/map">
          <v-list-item-content>
            <v-list-item-title
              style="font-family: 'Source Sans Pro', sans-serif !important; font-weight: bold;"
              class="white--text title ml-2"
            >
              Map
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/hardware">
          <v-list-item-content>
            <v-list-item-title
              style="font-family: 'Source Sans Pro', sans-serif !important; font-weight: bold;"
              class="white--text title ml-2"
              >DoCSoc Hardware Hub</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item to="/hardwareICRS">
          <v-list-item-content>
            <v-list-item-title
              style="font-family: 'Source Sans Pro', sans-serif !important; font-weight: bold;"
              class="white--text title ml-2"
              >ICRS Hardware Hub</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item to="/github">
          <v-list-item-content>
            <v-list-item-title
              style="font-family: 'Source Sans Pro', sans-serif !important; font-weight: bold;"
              class="white--text title ml-2"
            >
              GitHub
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item target="_blank" href="https://ichack23.slack.com/">
          <v-list-item-content>
            <v-list-item-title
              style="font-family: 'Source Sans Pro', sans-serif !important; font-weight: bold;"
              class="white--text title ml-2"
            >
              Slack
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item target="_blank" href="https://help.ichack.org">
          <v-list-item-content>
            <v-list-item-title
              style="font-family: 'Source Sans Pro', sans-serif !important; font-weight: bold;"
              class="white--text title ml-2"
            >
              Get A Mentor
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <!-- <v-list-item target="_blank" href="https://ic-hack-2023.devpost.com/">
          <v-list-item-content>
            <v-list-item-title
              style="font-family: 'Source Sans Pro', sans-serif !important; font-weight: bold;"
              class="white--text title ml-2"
            >
              DevPost
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <!-- <v-divider></v-divider> -->
        <v-list-item
          target="_blank"
          href="https://www.facebook.com/events/453958873035077/"
        >
          <v-list-item-content>
            <v-list-item-title
              style="font-family: 'Source Sans Pro', sans-serif !important; font-weight: bold;"
              class="white--text title ml-2"
            >
              Facebook
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item target="_blank" href="https://www.twitter.com/ichackuk">
          <v-list-item-content>
            <v-list-item-title
              style="font-family: 'Source Sans Pro', sans-serif !important; font-weight: bold;"
              class="white--text title ml-2"
            >
              Twitter
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item target="_blank" href="https://www.instagram.com/ichackuk">
          <v-list-item-content>
            <v-list-item-title
              style="font-family: 'Source Sans Pro', sans-serif !important; font-weight: bold;"
              class="white--text title ml-2"
            >
              Instagram
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar color="#C5B4E3" app fixed dark >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <img
          style="max-height: 40px; margin: 10px; float: right ; static:
          "
          alt="Vue logo"
          src="./assets/icrs_logo_transparent.png"
        />
      <v-toolbar-title
        style="font-family: 'Source Sans Pro', sans-serif !important; font-weight: bold;"
        class="display-1"
      >
        IC Hack 23 Hub
      </v-toolbar-title>
    </v-app-bar>
    <v-content>
      <router-view></router-view>
    </v-content>
    <v-footer color="#C5B4E3" app>
      <span class="white--text ml-3">&copy; ICHack-Hub 2023</span>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    drawer: null
  }),
  props: {
    source: String
  }
};
</script>

<style>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
/* .body {
  background-color: #002147;
} */
</style>
