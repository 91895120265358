<template>
  <v-container fluid>
    <h1>DoCSoc Hardware Hub</h1>
    <h3>
      We have a wide variety of hardware available for IC Hack, if there is
      something that isn't listed please contact a IC Hack organiser or message
      us on Slack, it may be that someone doesn't need theirs anymore!
    </h3>
    <HardwareForm collection="hardware" />
  </v-container>
</template>

<script>
import HardwareForm from "@/components/HardwareForm";

export default {
  components: { HardwareForm }
};
</script>
