import Vue from "vue";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import FieryVue from "fiery-vue";

import router from "./router";

Vue.use(FieryVue);

const firebaseConfig = {
  apiKey: "AIzaSyBZAB-b7VK4-eqHz_EvIhOtfQKTuFqcLO4",
  authDomain: "ichack-hub.firebaseapp.com",
  databaseURL: "https://ichack-hub.firebaseio.com",
  projectId: "ichack-hub",
  storageBucket: "ichack-hub.appspot.com",
  messagingSenderId: "412678275016"
};

const app = firebase.initializeApp(firebaseConfig);
export default firebase.firestore(app);

// Auth
const auth = firebase.auth(app);
auth.onAuthStateChanged(user => {
  if (user && user.email == "ichack@ic.ac.uk") {
    if (router.currentRoute.name != "admin") {
      router.push("/admin");
    }
  } else if (user && user.email == "icrs@ic.ac.uk") {
    if (router.currentRoute.name != "adminICRS") {
      router.push("/adminICRS");
    }
  } else if (
    router.currentRoute.name == "admin" ||
    router.currentRoute.name == "adminICRS"
  ) {
    firebase.auth().signOut();
    router.push("/");
  }
});
