<template>
  <v-form ref="form" v-model="valid">
    <v-layout ml-1 row wrap>
      <v-flex xs6 md2 mr-3>
        <v-text-field
          v-model="name"
          label="Full Name"
          required
          lazy-validation
          :rules="nameRules"
        ></v-text-field>
      </v-flex>
      <v-flex xs4 md1 mr-3>
        <v-text-field
          v-model="table"
          type="number"
          label="Table"
          required
          lazy-validation
          :rules="tableRules"
        ></v-text-field>
      </v-flex>
      <v-flex xs6 md2>
        <v-text-field
          v-model="phone"
          label="Phone Number"
          required
          lazy-validation
          :rules="phoneRules"
        ></v-text-field>
      </v-flex>
      <v-btn
        @click="submit"
        color="success"
        class="ma-1"
        large
        :disabled="!valid"
      >
        Reserve
      </v-btn>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12>
        <v-alert
          border="left"
          dismissible
          my-0
          py-0
          v-model="reservedDialog"
          type="success"
          transition="scale-transition"
        >
          Thank you for reserving hardware. Please go to the
          {{ collection }} Hub within 30 mins to pick it up.
        </v-alert>
        <v-alert
          border="left"
          dismissible
          my-0
          py-0
          v-model="failedDialog"
          type="warning"
          transition="scale-transition"
        >
          Please select at least one item below
        </v-alert>
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-space-around>
      <v-flex xs8 sm4 md3 lg2 ma-2 v-for="(h, i) in hardware" :key="i">
        <v-card height="450px">
          <v-img :src="h.pic" height="220px" class="pa-3" contain />
          <v-card-text class="headline text-center">
            {{ h.name }}
          </v-card-text>
          <v-card-text>
            <v-layout row wrap>
              <v-flex xs6 px-1>
                <v-chip style="width:100%" color="green" text-color="white">
                  <v-avatar class="green darken-3">
                    {{ h.available }}
                  </v-avatar>
                  <div class="ml-1">
                    Available
                  </div>
                </v-chip>
              </v-flex>
              <v-flex xs6 px-1>
                <v-chip style="width:100%" color="yellow" text-color="black">
                  <v-avatar class="yellow darken-3">
                    {{ h.reserved + h.inUse }}
                  </v-avatar>
                  <div class="ml-1">
                    Reserved
                  </div>
                </v-chip>
              </v-flex>
              <v-flex xs12 pa-2>
                Reserve:
                <v-select
                  :items="Array.from(Array(h.available + 1).keys())"
                  label="Amount"
                  :value="0"
                  :ref="'item' + i"
                  required
                ></v-select>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-form>
</template>

<script>
import db from "../firebase";

const phoneUKRegex = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/g;

export default {
  props: ["collection"],
  fiery: true,
  data() {
    return {
      valid: false,
      name: "",
      nameRules: [v => !!v || "Name is required"],
      table: "",
      tableRules: [
        v => v > 0 || "Sorry, only positive tables tables",
        v => !!v || "Table is required",
        v => v.length <= 5 || "Table must be less than 10 characters"
      ],
      phoneRules: [
        p => !!p || "Phone is required",
        p => phoneUKRegex.test(p) || "Not a valid UK number"
      ],
      phone: "",
      reservedDialog: false,
      failedDialog: false,
      hardware: this.$fiery(db.collection(this.collection), {
        sub: {
          reservations: {}
        }
      })
    };
  },
  methods: {
    submit(e) {
      e.preventDefault();
      this.reservedDialog = false;
      this.failedDialog = false;
      for (let i in this.hardware) {
        this.$fiery.refresh(this.hardware[i]);
        const amount = parseInt(this.$refs["item" + i][0].lazyValue);
        if (amount > 0) {
          this.reservedDialog = true;
          this.$fiery.createSub(this.hardware[i], "reservations", {
            name: this.name,
            table: this.table,
            phone: this.phone,
            amount: amount,
            loaned: false,
            returned: false,
            reservedTime: new Date().toString()
          });
          this.hardware[i].available -= amount;
          this.hardware[i].reserved += amount;
          this.$fiery.sync(this.hardware[i]);
          this.$refs["item" + i][0].lazyValue = 0;
        }
      }
      if (!this.reservedDialog) {
        this.failedDialog = true;
      }
    }
  }
};
</script>
